import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { ChevronRightIcon } from '@heroicons/react/outline'



export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-green-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h3 className="text-xl font-bold text-white sm:text-2xl">
                        <span className="block">Débarrassez-vous durablement des fissures</span>
                        </h3>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                        Fissuration.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration.
                        </p>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                          <a href="/maison/fissures-maison/expertise/marketplace/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Fissuration-general-sidebar.jpg" 
                              alt="Fissuration"
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-green-200">
                          <a href="/maison/fissures-maison/expertise/marketplace/" className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>


                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Solutions en cas de fissures
                          </h3>
                          <ul className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Expertises indépendante</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Etude de sol</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Reprise en sous-oeuvre</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Renforcement structurel</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Embellissement</li>
                          </ul>

                          <a
                            href="/maison/fissures-maison/contact/"
                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 sm:w-auto"
                            >
                            Trouvez un pro près de chez vous
                            </a>
                        </div>
                      </div>
                    </div>

                    
                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Articles et liens
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <a 
                              href="/maison/fissures-maison/aide/ain/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Ain (01)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/allier/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Allier (03)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/ardeche/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Ardèche (07)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/cantal/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Cantal (15)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/drome/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Drôme (26)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/isere/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Isère (38)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/loire/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Loire (42)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/haute-loire/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Haute-Loire (43)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/puy-de-dome/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Puy-de-Dôme (63)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/rhone/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Rhône (69)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/savoie/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Savoie (73)
                            </a>
                            <a 
                              href="/maison/fissures-maison/aide/haute-savoie/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures maison Haute-Savoie (74)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    

                    
            </div>



        )
    }